import React, { useEffect } from "react";
import { useAppLaunchProvider } from "../../contextProviders/AppLaunchProvider";
import { useAppStateProvider } from "../../contextProviders/AppStateProvider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { userActions } from "../../contextProviders/constant";
import Loader from "@moneris-portal/loader";
import Notification from "@moneris-portal/notification";
import createTranslator from "../../util/translationHelper";
import messages from "./translations/messages";
import { PrimaryButton } from "@moneris-portal/button";
import { env } from "../../cfg/env";
import "./index.scss";

function Launch({ lang }) {
  const { updateAppLaunchState, appLaunchState, updateAppLaunchStateLocal } =
    useAppLaunchProvider();
  const { appState = {} } = useAppStateProvider();
  // const [url, setRedirectUrl] = useState("");
  // const { apps } = appState;
  const history = useHistory();
  const { isLoading, isError, isRedirected } = appLaunchState;
  const translate = createTranslator(messages, lang);

  useEffect(() => {
    if (isRedirected) {
      updateAppLaunchStateLocal({ isRedirected: false });
      history.push(`/${lang}/home`);
    }
  }, [isRedirected]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window?.location?.search) ?? "";
    const code = searchParams.get("code");
    const appcode = searchParams.get("state");
    const tempMgoRedirectUrl =
      window.localStorage?.getItem("tempMgoRedirectUrl") ?? null;
    // todo
    // automate process in refactor. remove hardcoded urls
    const appUrlGo = {
      en: {
        DEV: "https://go-cert.moneris.io/login", // defaulting to qa since no dev link
        QA: tempMgoRedirectUrl
          ? tempMgoRedirectUrl
          : "https://go-int1.moneris.io/en/login",
        PROD: "https://www.monerisgo.com/en/login",
      },
      fr: {
        DEV: "https://go-cert.moneris.io/login", // defaulting to qa since no dev link
        QA: tempMgoRedirectUrl
          ? tempMgoRedirectUrl
          : "https://go-int1.moneris.io/fr/login",
        PROD: "https://www.monerisgo.com/fr/login",
      },
    };
    if (code && appcode) {
      const payload = {
        type: userActions.authToken,
        body: {
          code: code,
          redirectUrl: appUrlGo[lang][env?.appEnv ?? "prod"],
          method: "post",
          lang: lang,
        },
      };
      updateAppLaunchState(payload);
    }
  }, []);

  return (
    <div id="launch_app_container">
      {" "}
      {isError && (
        <>
          <div>
            <Notification variant="danger">
              {translate("invalid_message")}
            </Notification>
          </div>
          <div>
            <PrimaryButton
              title={"home"}
              clickHandler={() => history.push(`/${lang}/home`)}
            />
          </div>
        </>
      )}
      {isLoading && <Loader lang={lang} />}
    </div>
  );
}

export default Launch;
