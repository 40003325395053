import { useMsal } from "@azure/msal-react";
import { b2cPolicies } from "../authConfig";

function injectClassComponent(Component) {
  const InjectClassComponent = function (props) {
    const { instance } = useMsal();

    const handleEditPassword = () => {
      instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
      return null;
    };
    const handleEditSecQuestion = () => {
      instance.loginRedirect(b2cPolicies.authorities.editSelfServeSecQuestion);
      return null;
    };
    return (
      <Component
        {...props}
        handlePasswordChange={() => handleEditPassword()}
        handleEditSecQuestion={() => handleEditSecQuestion()}
      />
    );
  };
  return InjectClassComponent;
}

export default injectClassComponent;
