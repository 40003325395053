import React, { useState, useEffect } from "react";
import ToolTip from "@moneris-portal/tooltip";
import PropTypes from "prop-types";
import { SmoothInfoIcon } from "@moneris-portal/svg-components";
import { usePrevious } from "@moneris-portal/package-helpers";
import Collapsible from "@moneris-portal/collapsible";
import { ToastMessage, addToastMessage } from "@moneris-portal/toastmessage";
import { PrimaryButton } from "@moneris-portal/button";
import { RadioButton } from "@moneris-portal/input";
import { userActions } from "../../../../contextProviders/constant";
import AuthSetUpModal from "./AuthSetUpModal";
import VerifyModal from "../commonComponents/VerifyModal";
import EditModalPhone from "../commonComponents/EditModalPhone";
import injectClassComponent from "../../../../util/hookHoc";
import DateFormatter from "../../../../util/dateFormatter";
import createTranslator from "../../../../util/translationHelper";
import { capitaliseFirstLetter } from "../../../../util/helper";
import messages from "../Translations/messages";
import "./index.scss";
import PhoneValidator from "../../../../util/phoneValidator";
import handleWindowClose from "../../../..//util/windowClose";

const Security = ({
  validatePinSuccessFlag,
  errorCode,
  userPasswordUpdatedFlag,
  resetTotpSuccessFlag,
  userPhoneUpdatedFlag,
  validatePinSuccessPhoneFlag,
  isTwoFaSet,
  handlePasswordChange,
  handleEditSecQuestion,
  dispatch,
  totpSecretFetchErrorFlag,
  userName,
  twofaEnabled = true,
  securityInfo,
  accountInfo,
  containerId,
}) => {
  const lang = window.localStorage?.getItem("lang") ?? "en";
  const [showAuthSetupModal, setShowAuthSetupModal] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const [showSmsVerificationModal, setShowSmsVerificationModal] =
    useState(false);
  const [editSms, setEditSms] = useState(false);
  const [smsPhone, setSmsPhone] = useState("");
  const translate = createTranslator(messages);

  const handleModalVisibility = () => {
    setShowAuthSetupModal(false);
    setEditSms(false);
    setShowSmsVerificationModal(false);
    setSmsPhone("");
    handleWindowClose(false);
  };

  useEffect(() => {
    if (validatePinSuccessFlag) {
      handleModalVisibility();
    }
  }, []);

  const prevProps = usePrevious({
    validatePinSuccessFlag,
    userPhoneUpdatedFlag,
    validatePinSuccessPhoneFlag,
    resetTotpSuccessFlag,
    isTwoFaSet,
  });

  useEffect(() => {
    if (
      prevProps?.validatePinSuccessFlag === null &&
      validatePinSuccessFlag !== null &&
      activeItem !== ""
    ) {
      setShowAuthSetupModal(false);
      setShowSmsVerificationModal(false);
      addToastMessage({
        variant: validatePinSuccessFlag ? "success" : "danger",
        message: validatePinSuccessFlag
          ? `${capitaliseFirstLetter(activeItem)} ${
              activeItem === translate("error_email")
                ? translate("success_update")
                : translate("success_update_profile")
            }`
          : errorCode === 500
          ? translate("error_something_wrong")
          : `${
              activeItem === translate("error_email")
                ? translate("error_updating")
                : translate("error_updating_profile")
            } ${activeItem}.`,
      });
    }

    if (
      prevProps?.userPhoneUpdatedFlag === null &&
      userPhoneUpdatedFlag !== null
    ) {
      setEditSms(false);
      if (userPhoneUpdatedFlag) {
        setShowSmsVerificationModal(userPhoneUpdatedFlag);
      } else {
        setShowSmsVerificationModal(false);
        setSmsPhone("");

        addToastMessage({
          variant: "danger",
          message: translate("error_phone_update"),
        });
      }
    }

    if (
      prevProps?.validatePinSuccessPhoneFlag === null &&
      validatePinSuccessPhoneFlag !== null &&
      activeItem !== ""
    ) {
      setShowSmsVerificationModal(false);

      addToastMessage({
        variant: validatePinSuccessPhoneFlag ? "success" : "danger",
        message: validatePinSuccessPhoneFlag
          ? `${capitaliseFirstLetter(activeItem)} ${translate("success_update")}`
          : `${translate("error_updating")} ${activeItem}.`,
      });
    }

    if (
      prevProps?.isTwoFaSet === null &&
      isTwoFaSet !== null &&
      activeItem !== ""
    ) {
      addToastMessage({
        variant: isTwoFaSet ? "success" : "danger",
        message: isTwoFaSet
          ? `${capitaliseFirstLetter(activeItem)} ${translate("success_update")}`
          : `${translate("error_updating")} ${activeItem}.`,
      });
    }

    if (
      prevProps?.resetTotpSuccessFlag === null &&
      resetTotpSuccessFlag !== null &&
      activeItem !== ""
    ) {
      addToastMessage({
        variant: resetTotpSuccessFlag ? "success" : "danger",
        message: resetTotpSuccessFlag
          ? translate("totp_successful_msg")
          : translate("totp_failure_msg"),
      });
    }
  }, [
    validatePinSuccessFlag,
    errorCode,
    userPasswordUpdatedFlag,
    resetTotpSuccessFlag,
    userPhoneUpdatedFlag,
    validatePinSuccessPhoneFlag,
    isTwoFaSet,
  ]);

  const setExpiredCodeNotification = () => {
    addToastMessage({
      variant: "danger",
      message: translate("error_code_exipred"),
    });
  };

  const handleAuthAppSetup = () => {
    setShowAuthSetupModal(true);
    handleWindowClose(true);
    setActiveItem(translate("authenticator"));
  };

  const fetchTotpSecret = () => {
    dispatch({ type: userActions.sendTotpInfo, body: {} });
  };

  const validatePin = ({ pin, method }) => {
    dispatch({
      type: userActions.verifyTotpCode,
      body: { totpCode: pin },
      postCallHandler: handleMethod,
    });
  };

  const handleMethod = (method = null) => {
    if (method) {
      dispatch({
        type: userActions.updateUserInfo,
        body: { mfa_method: method },
      });
      setActiveItem(translate(method));
    }
  };

  const handleTotpReset = () => {
    dispatch({
      type: userActions.resetTotp,
      body: {},
    });
  };

  const handlePhoneEdit = () => {
    setActiveItem(translate("phone"));
    handleWindowClose(true);
    setEditSms(true);
  };

  const updateUserPhonePin = (payload) => {
    dispatch({
      type: userActions.verifyMfaCode,
      body: { ...payload },
      postCallHandler: handleMethod,
    });
  };

  const updateUserPhone = (phone) => {
    dispatch({
      type: userActions.sendMfaCode,
      body: { phone_number: phone, nonce: "12345abcd", mfa_method: "phone" },
    });
    setSmsPhone(phone);
  };

  const {
    userEmail = "",
    userPhone = "",
    mfa = {},
    passLastChange,
    passExpiry,
    secQuestionlastUpdate
  } = accountInfo;
  const passLastUpdatedDate = DateFormatter.getDate(passLastChange);
  const secQuestionLastUpdateDate = DateFormatter.getDate(secQuestionlastUpdate);
  const passExpiryDate = DateFormatter.getDate(passExpiry);
  const daysLeft = DateFormatter.getDaysBetweenDates(passExpiry);

  const {
    activeMethod = "email",
    hasPhoneSetUp = false,
    hasTotpSetUp = false,
  } = mfa;

  const { otpUri = "", totpSecret } = securityInfo;

  return (
    <div className="security_container">
      <div id={containerId} className="security_content_container">
        <div id="item1" data-scroll-id={translate("password")}>
          <Collapsible title={translate("password")} active>
            <div className="password_body">
              <div className="body_row horizontal">
                <div className="body_cell one">{translate("password")}</div>
                <div className="body_cell two">
                  <PrimaryButton
                    title={translate("change")}
                    style={{ maxWidth: "13rem" }}
                    clickHandler={handlePasswordChange}
                    isDisabled={false}
                  />
                </div>
              </div>
              <div className="body_row">
                <div className="body_cell one">
                  {translate("last_change")} &nbsp;&nbsp;
                </div>
                <div className="body_cell two">
                  {passLastUpdatedDate !== "Invalid Date"
                    ? passLastUpdatedDate
                    : ""}
                </div>
              </div>
              {/* <div className="body_row">
                <div className="body_cell one">
                  <div>{translate("date_expiry")}</div>
                  <ToolTip
                    message={translate("date_expiry_tooltip")}
                    toolTipStyle={{
                      width: "8rem",
                      // Have to ensure css leakage won't affact this
                      // TODO: remove this once css leakage is solved
                      position: "absolute",
                      fontWeight: "normal",
                    }}
                  >
                    <SmoothInfoIcon width={24} height={24} />
                  </ToolTip>
                </div>
                <div className="body_cell two">
                  {passExpiryDate !== "Invalid Date" ? passExpiryDate : ""}{" "}
                  {daysLeft !== "Invalid Date"
                    ? `(${daysLeft} ${translate("days_remaining")})`
                    : ""}
                </div>
              </div> */}
            </div>
          </Collapsible>
        </div>
        <div id="item3" data-scroll-id={translate("sec_question")}>
          <Collapsible title={translate("sec_question_title")} active>
            <div className="password_body">
              <div className="body_row horizontal">
                <div className="body_cell one">{translate("sec_question_label")}</div>
                <div className="body_cell two">
                  <PrimaryButton
                    title={translate("sec_question_button_label")}
                    style={{ maxWidth: "15rem" }}
                    clickHandler={handleEditSecQuestion}
                    isDisabled={false}
                  />
                </div>
              </div>
              <div className="body_row">
                <div className="body_cell one">
                  {translate("last_change")} &nbsp;&nbsp;
                </div>
                <div className="body_cell two">
                  {secQuestionLastUpdateDate !== "Invalid Date"
                    ? secQuestionLastUpdateDate
                    : ""}
                </div>
              </div>
            </div>
          </Collapsible>
        </div>
        {twofaEnabled && (
          <div
            id="item2"
            data-scroll-id={translate("two_factor_authentication")}
          >
            <Collapsible
              title={
                <div className="two_factor_authentication_bar">
                  <div>{translate("two_factor_authentication")}</div>
                  <ToolTip
                    message={translate("enable_tooltip")}
                    toolTipStyle={{
                      width: "12rem",
                      // Have to ensure css leakage won't affact this
                      // TODO: remove this once css leakage is solved
                      position: "absolute",
                      fontWeight: "normal",
                    }}
                  >
                    <SmoothInfoIcon width={24} height={24} />
                  </ToolTip>
                </div>
              }
              active
            >
              <div className="two_fa_body">
                <div className="radio_button_row">
                  <RadioButton
                    isChecked={activeMethod === "email"}
                    changeHandler={() => handleMethod("email")}
                    name="email"
                    value="email"
                  />

                  <div className="radio_content">
                    <div className="title">{translate("email")}</div>{" "}
                    <div className="add_ellipsis">{userEmail}</div>
                  </div>
                </div>

                {hasTotpSetUp && (
                  <div className="radio_button_row">
                    <RadioButton
                      isChecked={activeMethod === "totp"}
                      changeHandler={() => handleMethod("totp")}
                      name="totp"
                      value="totp"
                    />

                    <div className="radio_content">
                      <div className="title">
                        {translate("set_authenticator_app")}
                      </div>
                      <PrimaryButton
                        title={translate("remove_app")}
                        clickHandler={handleTotpReset}
                      />
                    </div>
                  </div>
                )}

                {!hasTotpSetUp && (
                  <div className="radio_button_row">
                    <RadioButton
                      isChecked={activeMethod === "totp"}
                      isDisabled
                      name="totp"
                      value="totp"
                    />

                    <div className="radio_content">
                      <div className="title">
                        <div>{translate("set_authenticator_app")}</div>

                        <ToolTip
                          message={translate(
                            "tooltip_message_info_radiobutton_2fa"
                          )}
                          toolTipStyle={{
                            width: "12rem",
                            // Have to ensure css leakage won't affact this
                            // TODO: remove this once css leakage is solved
                            position: "absolute",
                            fontWeight: "normal",
                          }}
                        >
                          <SmoothInfoIcon width={24} height={24} />
                        </ToolTip>
                      </div>
                      <PrimaryButton
                        title={translate("setup")}
                        clickHandler={handleAuthAppSetup}
                      />
                    </div>
                  </div>
                )}

                {hasPhoneSetUp && (
                  <div className="radio_button_row">
                    <RadioButton
                      isChecked={activeMethod === "phone"}
                      changeHandler={() => handleMethod("phone")}
                      name="phone"
                      value="phone"
                    />

                    <div className="radio_content">
                      <div className="title">{translate("text_message")}</div>
                      {userPhone && userPhone !== "" && (
                        <div>{PhoneValidator(userPhone)}</div>
                      )}
                      <PrimaryButton
                        title={translate("edit")}
                        clickHandler={handlePhoneEdit}
                      />
                    </div>
                  </div>
                )}

                {!hasPhoneSetUp && (
                  <div className="radio_button_row">
                    <RadioButton
                      isChecked={activeMethod === "phone"}
                      isDisabled
                      name="phone"
                      value="phone"
                    />
                    <div className="radio_content">
                      <div className="title">
                        <div> {translate("text_message")}</div>
                        <ToolTip
                          message={translate(
                            "tooltip_message_info_radiobutton_phone"
                          )}
                          toolTipStyle={{
                            width: "12rem",
                            // Have to ensure css leakage won't affact this
                            // TODO: remove this once css leakage is solved
                            position: "absolute",
                            fontWeight: "normal",
                          }}
                        >
                          <SmoothInfoIcon width={24} height={24} />
                        </ToolTip>
                      </div>
                      {userPhone && userPhone !== "" && (
                        <div>{PhoneValidator(userPhone)}</div>
                      )}
                      <PrimaryButton
                        title={translate("setup")}
                        clickHandler={handlePhoneEdit}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Collapsible>
          </div>
        )}
      </div>

      {showAuthSetupModal && (
        <AuthSetUpModal
          showModal={showAuthSetupModal}
          handleModalVisibility={handleModalVisibility}
          fetchTotpSecret={fetchTotpSecret}
          otpUri={otpUri}
          totpSecret={totpSecret}
          totpSecretFetchErrorFlag={totpSecretFetchErrorFlag}
          validatePin={validatePin}
          validatePinSuccessFlag={validatePinSuccessFlag}
          errorCode={errorCode}
          userName={userName}
        />
      )}

      {editSms && (
        <EditModalPhone
          showModal={editSms}
          handleModalVisibility={handleModalVisibility}
          updateUserPhone={updateUserPhone}
        />
      )}

      {showSmsVerificationModal && (
        <VerifyModal
          showModal={showSmsVerificationModal}
          handleModalVisibility={handleModalVisibility}
          smsPhone={smsPhone}
          mfaMethod="phone"
          validatePin={updateUserPhonePin}
          validatePinSuccessFlag={validatePinSuccessFlag}
          setExpiredCodeNotification={setExpiredCodeNotification}
        />
      )}
    </div>
  );
};

Security.defaultProps = {
  validatePinSuccessFlag: null,
  userEmailUpdatedFlag: null,
  userPhoneUpdatedFlag: null,
  resetTotpSuccessFlag: null,
  errorCode: null,
  totpSecretFetchErrorFlag: null,
  userPasswordUpdatedFlag: null,
};

Security.propTypes = {
  fetchTotpSecret: PropTypes.func.isRequired,
  totpSecretFetchErrorFlag: PropTypes.oneOf([null, true, false]),
  updateUserEmail: PropTypes.func.isRequired,
  resetTotpSuccessFlag: PropTypes.oneOf([null, true, false]),
  updateUserPhone: PropTypes.func.isRequired,
  userEmailUpdatedFlag: PropTypes.oneOf([null, true, false]),
  userPhoneUpdatedFlag: PropTypes.oneOf([null, true, false]),
  errorCode: PropTypes.any,
  validatePinSuccessFlag: PropTypes.oneOf([null, true, false]),
  updateTotp: PropTypes.func.isRequired,
  updateTwoFa: PropTypes.func.isRequired,
  validatePin: PropTypes.func.isRequired,
  user_name: PropTypes.string.isRequired,
  user_email: PropTypes.string.isRequired,
  user_phone: PropTypes.string.isRequired,
  twofaEnabled: PropTypes.bool.isRequired,
  twofa_totp_enable: PropTypes.bool.isRequired,
  password_expiry: PropTypes.any.isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  userPasswordUpdatedFlag: PropTypes.oneOf([null, true, false]),
  lastPasswordUpdateDate: PropTypes.any.isRequired,
};

export default injectClassComponent(Security);
