const applicationDescription = {
  md2: {
    desc: "this_is_your_business_hub",
    list: [
      "view_monthly_statement_and_reports",
      "order_additional_devices",
      "manage_chargebacks_and_disputes",
    ],
    urlCode: "merchant_direct_portal_secondary_link",
  },
  de: {
    desc: "this_is_your_business_hub",
    list: [
      "view_monthly_statement_and_reports",
      "order_additional_devices",
      "manage_chargebacks_and_disputes",
    ],
    urlCode: "merchant_direct_portal_secondary_link",
  },
  mogo: {
    desc: "go_portal_desc_main",
    list: [
      "go_portal_desc_point1",
      "go_portal_desc_point2",
      "go_portal_desc_point3",
    ],
    urlCode: "go_portal_business_secondary_link",
  },
  idi: {
    desc: "moneris_insight_desc_main",
    list: [
      "moneris_insight_desc_point1",
      "moneris_insight_desc_point2",
      "moneris_insight_desc_point3",
      "moneris_insight_desc_point4",
    ],
    urlCode: "moneris_insight_secondary_link",
  },
};

export default applicationDescription;
