import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { env } from "../cfg/env";
import clearMemory from "./storageHandler";

const getToken = async(
  instance,
  inProgress = "",
  accounts = [],
  setError = () => null
) => {
  const accountList = instance.getAllAccounts() ?? [];
  if (accountList.length) {
    const accessTokenRequest = {
      scopes: [`https://${env.sso.b2cTenantDomain}.onmicrosoft.com/sso/auth`],
      account: accounts[0],
    };
    return instance
      ?.acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        const idToken = accessTokenResponse.idToken ?? "";
        return idToken;
      })
      .catch((error) => {
        // removing logic for acquiring token using popup
        // if (error instanceof InteractionRequiredAuthError) {
        //   instance
        //     ?.acquireTokenPopup(accessTokenRequest)
        //     .then(function (accessTokenResponse) {
        //       // Acquire token interactive success
        //       let accessToken = accessTokenResponse.idToken;
        //       return accessToken;
        //     })
        //     .catch(function (e) {
        //       // Acquire token interactive failure
        //       console.log(e);
        //       setError(true);
        //     });
        // }
        if (error instanceof InteractionRequiredAuthError) {
          console.log("error is:", error);
          if (accounts.length > 0) {
            const currentAccount = accounts[0];
            const homeAccountId = currentAccount.homeAccountId;
            console.log(homeAccountId);
            const logoutRequest = {
              account: instance.getAccountByHomeId(homeAccountId),
            };
            instance.logoutRedirect(logoutRequest);
            // Use homeAccountId as needed
          } else {
            // Handle the case where no user is signed in
            console.log("No user is currently signed in.");
          }
        }
        setError(true);
        console.log(error);
        return null;
      });
  }else{
    instance.logoutRedirect();
  }
  return null;
};

export default getToken;
