/* eslint-disable */

import axios from "axios";
import { env } from "../cfg/env";

async function CallApi(
  body,
  url,
  method,
  contentType = "application/json",
  IsWithCredentials = true,
  token = null
) {
  if (method && method.toUpperCase() === "POST") {
    try {
      const response = await axios.post(url, body, {
        headers: {
          "Content-Type": contentType,
          ...(token && { Authorization: `Bearer ${token}` }),
        },
       withCredentials: IsWithCredentials,
      });
      return response;
    } catch (e) {
      return e.response;
    }
  } else if (method && method.toUpperCase() === "GET") {
    try {
      const response = await axios.get(url, {
        withCredentials: IsWithCredentials,
        "Content-Type": contentType,
        ...(token && { Authorization: `Bearer ${token}` }),
        data: {},
      });
      return response;
    } catch (e) {
      return e.response;
    }
  } else if (method && method.toUpperCase() === "PUT") {
    try {
      const response = await axios.put(url, body, {
        headers: {
          "Content-Type": contentType,
        },
        withCredentials: IsWithCredentials,
      });
      return response;
    } catch (e) {
      return e.response;
    }
  } else {
    return "error";
  }
}

function validateStatus(status) {
  return status >= 200 && status < 300; // Success on response between 200 and 300
}

// Authenticated parameter is strictly for all api which are authenticated
export default async function authApi(payload, isAuthenticated = false) {
  const {
    body,
    url,
    method,
    contentType,
    isWithCredentials,
    tokenhandler = () => null,
    isSecured = false,
  } = payload;
  const token = isSecured ? await tokenhandler() : null;
  const response = await CallApi(
    body,
    url,
    method,
    contentType,
    isWithCredentials,
    token
  );
  return response;
}
