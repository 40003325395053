const handleEvent = (e) => {
  e.preventDefault();
  // Included for legacy support, e.g. Chrome/Edge < 119
  e.returnValue = true;
};

function handleWindowClose(isTabCloseTracked) {
  if (isTabCloseTracked) {
    window?.addEventListener("beforeunload", handleEvent,true);
  } else {
    window?.removeEventListener("beforeunload", handleEvent, true);
  }
}

export default handleWindowClose;
