import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import StatementNavigation from "@moneris-portal/statement-navigation";
import NavigationTab from "@moneris-portal/navigation-tab";
import PageHeader from "@moneris-portal/page-header";
import Loader from "@moneris-portal/loader";
import { ToastMessage } from "@moneris-portal/toastmessage";
import Security from "../../components/Setting/components/Security/index";
import UserAccount from "../../components/Setting/components/UserAccount/index";
import getNonSsoUrl from "../../util/nonSsoRedirect";
import { useInfoProvider } from "../../contextProviders/InfoProvider";
import { useAppStateProvider } from "../../contextProviders/AppStateProvider";
import { userActions } from "../../contextProviders/constant";
import createTranslator from "../../util/translationHelper";
import messages from "./translations/messages";

import "./index.scss";

const containerIdMap = {
  User_Account: "user_profile_setting_navigation",
  Security: "security_setting_navigation",
};

function Setting({ lang }) {
  const { userInfo = {}, dispatch, callStatus = {} } = useInfoProvider();
  const { appState = {} } = useAppStateProvider();
  const history = useHistory();
  const { profile = {} } = appState;
  const { securityInfo = {} } = userInfo;
  const { status = null, type = "" } = callStatus;
  const translate = createTranslator(messages, lang);
  const searchParams = new URLSearchParams(window?.location?.search ?? "");
  const activeTab = searchParams?.get("action") ?? "User_Account";
  const redirectTo = searchParams?.get("redirectTo") ?? "";
  const path = searchParams?.get("path") ?? "";
  const [containerId, setContainerId] = useState(containerIdMap[activeTab]);
  const redirectUrl = searchParams?.get("redirectUrl") ?? null;

  const goBackHandler = () => {
    if (redirectTo !== "" || redirectUrl) {
      window.location.href = redirectUrl
        ? redirectUrl
        : `${getNonSsoUrl(redirectTo)}/${lang}${path}`;
    } else {
      history.push(`/${lang}/home`);
    }
  };

  const activeTabListener = (tab) => setContainerId(containerIdMap[tab]);

  const profileLoaded =
    typeof profile === "object" && Object.keys(profile).length > 0;

  const menuItems = {
    User_Account: {
      title: translate("user_account"),
      comp: (
        <UserAccount
          accountInfo={profile}
          dispatch={dispatch}
          userProfileUpdatedErrorFlag={
            type === userActions.updateUserInfo ? status : null
          }
          userPhoneUpdatedFlag={
            type === userActions.sendMfaCode ? status : null
          }
          validatePinSuccessFlag={
            type === userActions.verifyMfaCode ? status : null
          }
          containerId={containerIdMap.User_Account}
        />
      ),
    },
    Security: {
      title: translate("security"),

      comp: (
        <Security
          securityInfo={securityInfo}
          dispatch={dispatch}
          accountInfo={profile}
          userPhoneUpdatedFlag={
            type === userActions.sendMfaCode ? status : null
          }
          validatePinSuccessFlag={
            type === userActions.verifyTotpCode ? status : null
          }
          validatePinSuccessPhoneFlag={
            type === userActions.verifyMfaCode ? status : null
          }
          isTwoFaSet={type === userActions.updateUserInfo ? status : null}
          containerId={containerIdMap.Security}
          resetTotpSuccessFlag={type === userActions.resetTotp ? status : null}
        />
      ),
    },
  };

  return (
    <div id="setting-container">
      {profileLoaded && (
        <>
          <div className="upper_part">
            <PageHeader
              title={translate("user_setting")}
              isGoBack
              goBackHandler={goBackHandler}
              goBackVariant="arrow"
              goBackLabel={translate("go_to_moneris_portal")}
            />
          </div>
          <div className="lower_part">
            <StatementNavigation containerId={containerId} />
            <NavigationTab
              options={menuItems}
              activeTab={activeTab}
              activeTabListener={activeTabListener}
            />
          </div>
        </>
      )}
      {!profileLoaded && <Loader lang={lang} />}
      <ToastMessage maximumToasts={0} duration={3000} style={{ top: "4rem" }} />
    </div>
  );
}

export default Setting;
